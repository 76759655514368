* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  
  margin: 0px;
}

a{
  color: #333;
  text-decoration: none;
}



.number {
  min-width: 24%;
  height: 170px;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex: 1;
  color: #fff;
  background-color: #3399ff;
  font-size: 30px;
  font-weight: bold;
  position: relative;


  /* flex-grow: 1; */

}

.pop-text {
  font-size: 40px;

}

.text-outer {
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}

.number:active {
  /* //box-shadow: inset 0 0 10px #000000; */
  background: #0066ff;
  font-size: 35px;

}

.scratch-numbers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;


}

.modal-outer.open {
  opacity: 1;
  pointer-events: all;
}

.modal-inner {
  max-width: 600px;
  min-width: 400px;
  padding: 2rem;
  border-radius: 5px;
  min-height: 200px;
  background: white;
  transform: translateY(-200%);
  transition: transform 2s;

}

.modal-outer.open .modal-inner {
  transform: translateY(0);
}

.modal-outer {
  display: grid;
  background: hsla(243, 100%, 50%, 0.7);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  /* Hide this modal until we need it */
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}

.close {
  color: #aaa;
  float: right;
  font-size: 50px;
  font-weight: bold;
  text-decoration: none;
  background:none;
  border: none;
}

.hide {
  display: none;
}

.buttons {
  display: flex;
  justify-content: center;

}

.undo,
.new_day,
.total,
.edit_prices {
  /*width: 33%;*/
  flex: 1;
  height: 75px;
  font-size: 30px;
}

.card-count {
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 10px;
}
.card-price {
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 10px;
}

.number span{
  border: 1px solid white;
  padding: 3px;
  margin-right: 10px;
  background-color: white;
  color: #3399ff;
}

.prices-input-outside, .count-limit {
  display: flex;
  flex-wrap: wrap;
}

.prices-input {
  width: 50%;
}

.prices-input-outside .prices-input div, .count-limit .prices-input div {
  display: flex;
}

.tab1.active,.tab2.active {
  background-color: green;
  color: #fff;
}

.tab1,.tab2 {
  width: 50%;
  padding: 10px;
}

.tabs {
  display: flex;
}